<template>
  <div class="columns no-margin">
    <div class="column no-padding">
      <div class="card">
        <header class="card-header has-background-primary">
          <div @click.stop="$router.go(-1)" class="card-header-icon">
            <b-icon class="has-text-light" size="is-small" icon="arrow-left" />
          </div>
          <p class="card-header-title has-text-light">PANDUAN LOGBOOK</p>
        </header>
        <div class="card-content events-card">
          <p>
            <a
              @click.stop.prevent="openNewTab('pspd')"
            >
              Panduan Logbook Mahasiswa
              <b-icon size="is-small" icon="download" />
            </a>
          </p>
          <p>
            <a
              @click.stop.prevent="openNewTab('preceptor')"
            >
              Panduan Logbook Preceptor
              <b-icon size="is-small" icon="download" />
            </a>
          </p>
          <p>
            <a
              @click.stop.prevent="openNewTab('admin_departemen')"
            >
              Panduan Logbook Admin Departemen
              <b-icon size="is-small" icon="download" />
            </a>
          </p>
          <p>
            <a
              @click.stop.prevent="openNewTab('admin_pusat')"
            >
              Panduan Logbook Admin Pusat
              <b-icon size="is-small" icon="download" />
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import APP_CONFIG from "@/apps/core/modules/config.js";


const urlBase = `${APP_CONFIG.baseS3Url}/pspd/bantuan/`;

const pdfMap = {
  pspd: `${urlBase}logbook_PSPD.pdf`,
  preceptor: `${urlBase}logbook_preceptor.pdf`,
  admin_departemen: `${urlBase}logbook_admin_departemen.pdf`,
  admin_pusat: `${urlBase}logbook_admin_pusat.pdf`
}


export default {
  methods: {
    openNewTab(group) {
      window.open(pdfMap[group], "_blank");
    }
  }
};
</script>

<style scoped lang="scss">
.icon {
  vertical-align: middle;
  padding-bottom: 5px;
}
</style>